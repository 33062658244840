.center-content {
  height: 100%;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}
.container {
  max-width: 59vw !important;
}

.center-content-general {
  height: 50vh;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}

.vertical_adjust_30 {
  height: 70%;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}

.formCentral {
  width: 90%;
  color: white;
  margin-bottom: 10px;
}
.formCentral h2,
p {
  color: white;
}
.formCentral h2 {
  font-style: italic;
  font-weight: bold;
  font-size: 3vw;
}
.formCentral p {
  font-size: 2vw;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_bold {
  font-weight: bold;
}

.left_bottom {
  height: 90%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 5vw;
}

.formCentral .col-lg-6 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
#foto_mujer,
#foto_cuadro_naranja {
  width: 100% !important;
  height: auto;
}

.back_formCentral {
  position: relative;
}
.over_FormCentral {
  position: absolute;
  width: 100%;
  height: 100%;
}

.formCentral_button {
  background-color: #ff00a4;
  border: none;
  color: white;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  font-style: italic;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
}

.formCentral_clinicas {
  background-color: #bf628a;
  border: none;
  color: white;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: 100px;
}

.admin_input {
  color: #fff;
  background-color: #1a30fc;
  border-color: #1a30fc;
  padding: 3px 15px;
  font-weight: bold;
  width: 75%;
  margin: 10px 0px;
  font-size: 1vw !important;
  display: block;
}

.admin_input::placeholder {
  color: #fff;
  font-style: italic;
}
.formCentral_select .btn-primary:disabled {
  background-color: #1a30fc !important;
}

.form_login {
  background: #fc8902;
  color: white;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wildcard_container {
  margin: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .formCentral_select .btn-primary {
    width: 150px;
  }
  .container {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .formCentral h2 {
    font-size: 10vw;
  }
  .text_left {
    font-size: 2rem;
  }

  .formCentral_select .btn-primary {
    width: 100% !important;
    max-width: 100%;
    font-size: 12px !important;
  }
  .dropdown-menu.show {
    overflow: scroll;
    max-height: 20vh !important;
  }
}

@media screen and (max-width: 320px) {
  .over_FormCentral .center-content {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 800px) and (max-height: 420px) {
}
