.navbar-container {
  color: white;
  display: flex;
  flex-wrap: wrap;
}

.navbar-container-second-back {
  background: rgb(0, 0, 0);

  background: linear-gradient(355deg, #1323db 10%, #cc6aa361 50%, #fe9f5d 75%);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-container a {
  color: white;
  background: inherit;
  font-size: 16px;
  line-height: 20px;
  margin-right: 80px;
  font-family: Helvetica;

  font-style: normal;
  font-weight: lighter;
  padding: 0;
  text-decoration: none;
}

.navbar-container a:hover {
  text-decoration: underline !important;
}

.header-nav {
  background: rgb(28, 29, 141);

  width: 100vw;
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.nav-container div {
  display: flex;
  flex-wrap: wrap;
}

.nav-buttons {
  flex-wrap: wrap;
}

.nav-buttons .nav-menu {
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 20px 0;
}

.nav-buttons .nav-menu a {
  font-size: 20px;
  font-family: Helvetica;
  margin-right: 40px;
  text-transform: uppercase;
  padding: 10px 0 5px;
}

.nav-button {
  font-size: 20px;
  font-family: Helvetica;
  margin-right: 40px;
  text-transform: uppercase;
  padding: 10px 0 5px;
  border: none;
  background: transparent;
  color: white;
  font-weight: lighter;
}

.nav-assets {
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.nav-assets img {
  width: 4vw;
}

.nav-assets p {
  font-size: 64px;
  line-height: 1;
  font-weight: bold;
  font-style: italic !important;
  font-family: Helvetica;
}

.header-nav .red-gine-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nav .red-gine-container p {
  margin: 0;
  font-size: 1rem;
  color: white;
}

.buscador {
  margin-left: auto;
}

.buscador input {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: 24px;
  background-color: transparent;
  padding: 4px 0;
  max-width: 400px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: Helvetica;

  background-image: url('../../assets/lupe.svg');

  background-repeat: no-repeat;
  background-position: 95%;
}
.buscador input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 0.5; /* Firefox */
  font-style: italic;
}
@media screen and (max-width: 770px) {
  /* .navbar-container {
    height: 23vh;
  } */
  .nav-buttons .nav-menu {
    width: 100%;
  }

  .nav-assets img {
    width: 10vw;
  }
  .nav-assets p {
    font-size: 8vw;
  }

  .header-nav {
    height: 6vh;
  }
  /* .nav-buttons .nav-menu a {
    font-size: 1.4rem;
  }

  .header-nav .red-gine-container p {
    font-size: 0.7rem;
  } */
}

@media screen and (max-width: 800px) and (max-height: 420px) {
  .nav-assets p {
    font-size: 10vh !important;
  }
  .nav-assets img {
    width: 12vh !important;
  }
}

@media screen and (max-width: 320px) {
  /* .nav-buttons .nav-menu a {
    font-size: 0.8rem;
  } */
  /* .nav-buttons .nav-menu a {
    font-size: 0.9rem;
  } */
  /* .nav-buttons .nav-menu div:first-child a {
    font-size: 0.9rem;
    margin: 0 0 0 10px;
  } */
}
