* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
body {
  background: linear-gradient(#1323db, #cc6aa3) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
p {
  font-size: 1.5rem;
}

a {
  text-decoration: none !important;
  font-size: 1.5rem;
}
a.consent-link {
  text-decoration: underline !important;
  color: #0331fc !important;
  font-size: 14px;
}

a:active,
a:hover {
  color: black !important;
}
a.consent-link:hover,
a.consent-link:active {
  color: #0331fc !important;
}

*:focus {
  outline: none !important;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 2.1rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  p {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none !important;
    font-size: 1.2rem;
  }
}
