.clinicas-container {
  background: white;
  overflow: auto;
  flex: 1;
}

.header {
  margin: 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 100%;

  position: sticky;
  top: 0;
  background: white;
}

.header div:first-child {
  display: flex;
  flex-direction: column;
  min-width: 10%;
}

.header-container {
  min-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .header-container img {
  margin: 0;
  margin: auto;
  height: 7vh;
}
.header .header-container h1 {
  margin: 10px;
  color: #0418fc;
  font-weight: bold;
  font-style: italic;
  font-size: 4vh;
}

.listOfClinics {
  overflow: scroll;
  overflow-x: hidden;
}

.genero .btn-primary,
.genero .btn-primary:disabled {
  background-color: #fa8825 !important;
}
.genero .btn-primary:hover,
.genero .show .btn-primary.dropdown-toggle,
.genero .btn-primary:focus {
  background-color: #fa8825 !important;
  transform: scale(1.01);
}

.especialidad .btn-primary,
.especialidad .btn-primary:disabled {
  background-color: #9d40a1 !important;
  padding-right: 30px;
}

.especialidad .btn-primary:hover,
.especialidad .show .btn-primary.dropdown-toggle,
.especialidad .btn-primary:focus {
  background-color: #9d40a1 !important;
  transform: scale(1.01);
}

.experiencia .btn-primary:disabled,
.experiencia .btn-primary {
  background-color: #1c2afc !important;
}
.experiencia .btn-primary:hover,
.experiencia .show .btn-primary.dropdown-toggle,
.experiencia .btn-primary:focus {
  background-color: #1c2afc !important;
  transform: scale(1.01);
}

.horario .btn-primary,
.horario .btn-primary:disabled {
  background-color: #cc6aa3 !important;
}

.horario .btn-primary:hover,
.horario .show .btn-primary.dropdown-toggle,
.horario .btn-primary:focus {
  background-color: #cc6aa3 !important;
  transform: scale(1.01);
}

.btn-primary {
  width: 100%;
  border: 0 !important;
  font-size: 1.5rem !important;
}

.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 10px;
}

.start-clinics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.start-clinics img {
  width: 10%;
}
.start-clinics h1 {
  color: #fa8825;
  width: 60%;
  text-align: center;
}

.__react_component_tooltip {
  width: 35vw !important;
}

.filter-doctor {
  padding: 5px 10px;
  font-size: 14px;
  margin: 4px 2px;
  border: 1px solid gray;
  border-radius: 5px;
}
.admin-actions-container .back-button {
  background-color: #9d40a1;
}

.admin-actions-container .back-button.logout {
  background-color: #1c2afc;
}

@media screen and (max-width: 770px) {
  .__react_component_tooltip {
    width: 50% !important;
  }
  .clinicas-container {
    background: white;
    height: auto !important;
  }
  .listOfClinics {
    height: auto !important;
    overflow: scroll;
    padding-right: 0px;
  }

  .header .header-container img {
    height: 6vh;
  }

  .header h1 {
    font-size: 3vh !important;
    text-align: center;
    width: 100%;
  }
  .btn-primary {
    font-size: 1rem !important;
  }

  .especialidades-item {
    width: 50% !important;
  }
}

@media screen and (max-width: 550px) {
  .especialidades-item {
    width: 100% !important;
  }
}

@media screen and (max-width: 320px) {
  .header div:first-child {
    flex-direction: column-reverse;
    min-width: 100%;
  }
  .back-button {
    width: 100%;
  }
  .searchContainer .dropdown {
    width: 100%;
    margin: 10px auto;
  }

  .admin-actions-container {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 550px) {
  .admin-actions-container {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .filter-doctor {
    width: 100%;
  }
}
