.doctor-main {
  /* background: gray; */
  flex: 1;
  height: 98%;
}
.doctor-main .header {
  /* background: red; */
  justify-content: flex-start;
  padding: 5px;
  padding-left: 0;
}

.clinica-full {
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  width: 80%;
  margin: 1rem 0;
  /* background: red; */
}

.clinica-info {
  margin-left: 10px;
}

.doctor-info,
.clinica-info {
  /* background: pink; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.clinica-full .doctor-info {
  position: relative;
  max-width: 50%;
}

.doctor-info .doctor-img {
  /* background: gray; */
  display: flex;
  flex-direction: column;
}

.loader-picture {
  min-height: 50%;
  position: relative;
  min-width: 25rem;
}

.doctor-info .doctor-img img {
  /* background: red; */
  margin: 5px 0;
  max-width: 100%;
  max-height: 37rem;
  border-radius: 20px;
  align-self: center;
  filter: grayscale(100%);
}

.doctor-cursos {
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-cursos-container {
  display: flex;
  /* margin: 10px; */
  width: 40rem;
  justify-content: space-evenly;
}

.doctor-curso {
  flex: 0 0 30%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  /* background: white; */
}

.doctor-curso img {
  margin: 10px 0;
  width: 8rem;
}

.doctor-curso p {
  color: #0331fc;
  font-weight: bold;
}

.clinica-info {
  /* background: yellow; */
  align-items: flex-start;
  overflow: auto;
  flex: 1;
}

.doctor-credenciales {
  /* background: red; */
  width: 90%;
  margin: auto;
}

h2.doctor_title {
  color: #afafaf;
  font-size: 1.5rem;
}

h1.doctor_name {
  color: #1c2afc;
  font-size: 2.5rem;
  margin: 5px 0;
}

h3.doctor_specialty {
  font-size: 1rem;
  color: #1c2afc;
}

.clinica-body {
  /* background: pink; */
  max-width: 100%;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.carousel .slide {
  min-width: 100%;
  background: transparent;
}

.consultury_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background: gray; */
  width: 90%;
  margin: auto;
  overflow: auto;
}

p.clinic_address {
  text-align: left;
  color: black;
  font-weight: bold;
  margin: 5px 0;
}

.imagenes-consultorio {
  display: flex;
  margin: 5px 0;
  width: 100%;
  max-height: 30rem;
}

.imagen-clinica {
  /* background: grey; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  position: relative;
  max-height: 100%;
  /* min-height: 10rem; */
}

.imagen-body-clinica {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  max-height: 19rem;
}

.imagen-clinica img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  border-radius: 15px !important;
  filter: grayscale(100%);
  /* border: 1px solid black !important; */
  /* background: red; */
  margin: 5px;
}

.admin .imagen-clinica img {
  max-height: 80% !important;
  margin: 0px;
}

.imagen-clinica div {
  display: flex;
  flex-direction: column;
}

.horarios-container {
  display: flex;
  /* background: green; */
  width: 100%;
  flex-wrap: wrap;
}

.horary_box {
  background: #e5e5e5;
  margin: 3px;
  flex: 0 0 24%;
  border-radius: 10px;
  padding: 3px;
}

.horary_box p {
  font-family: Helvetica Neue;
  font-style: italic;
  color: #afafaf;
  margin: 0;
  padding: 0;
}
.horary_box p:nth-child(2) {
  font-size: 11px;
}
.horary_box img {
  margin: 5px 0 !important;
  height: 4rem !important;
  width: auto !important;
}

.isToday {
  background: #fc8902;
}

.isToday p {
  color: white;
}

.contact_row {
  display: flex;
  /* background: red; */
  margin-right: 10px;

  flex-wrap: wrap;
}

.clinica-footer {
  display: flex;
  /* background: pink; */
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 5px 0;
}

.container_contact {
  display: flex;
  align-items: center;
  padding: 5px;
  /* background: pink; */
}

.text_contact {
  display: flex;
  align-items: center;
  padding: 5px;
}

img.icon_contact {
  width: 4rem !important;
  margin: 0px 10px !important;
}

.tel-body {
  /* background: green; */
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tel-body a,
.tel-body p {
  font-size: 12px;
  text-decoration: none;
  color: black;
  margin: 0;
  text-align: left;
}

.text_contact a {
  font-size: 12px;
  text-decoration: none;
  color: black;
}

.control-next:before {
  content: url("../../../assets/Arrow.svg") !important;
  border-top: 0 solid transparent !important;
  border-bottom: 0 solid !important;
  border-left: 0px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  content: url("../../../assets/Arrow.svg") !important;
  transform: rotate(180deg);
  border-right: 0px solid #fff !important;
}

.start-clinics.no_doctors.fade-in-slow {
  margin: 0 auto;
}

.start-clinics.no_doctors.fade-in-slow img {
  width: 11rem;
}

.clinic-btns {
  display: flex;
  margin: 5px 0;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  /* background: red; */
}

.delete-overlay {
  background: rgba(1, 1, 1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}

.delete-container {
  background: white;
  width: 35%;
  height: 35%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.delete-container img {
  position: absolute;
  width: 2rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.delete-container h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #afafaf;
}

.delete-btns {
  /* background: red; */
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}

.delete-btns .back-button {
  width: 30%;
  font-size: 1.5rem;
}

.delete-spinner {
  width: 100%;
  height: 3rem;
  position: relative;
  bottom: -3rem;
}

.carousel.carousel-slider,
.carousel-root {
  height: 100% !important;
  overflow: auto !important;
}

.carousel .control-dots {
  position: relative !important;
  top: 0;
}

li.dot {
  background: gray !important;
}

li.dot.selected {
  background: #fc8902 !important;
}

.carousel.carousel-slider .control-arrow {
  padding: 0px !important;
}

@media (max-width: 1750px) {
  .clinica-full {
    width: 85%;
  }

  .horary_box {
    margin: 3px;
    flex: 0 0 23%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .clinica-full {
    width: 100% !important;
  }

  .carousel.carousel-slider .control-arrow {
    font-size: 10px !important;
    padding: 0px !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 850px) {
  .imagenes-consultorio {
    flex-direction: column !important;
    margin: 5px 0;
  }

  .imagen-clinica {
    margin: 0;
  }

  .imagenes-consultorio {
    display: flex;
    margin: 5px 0;
    /* background: red; */
    width: 100%;
    height: auto;
    max-height: 100%;
  }
  .imagen-body-clinica {
    max-height: 100%;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .doctor-info {
    min-width: 100%;
    max-width: 100%;
  }

  .clinica-info {
    overflow: hidden !important;
    flex: none;
    width: 100%;
  }

  .clinica-full {
    overflow: hidden !important;
    height: auto;
  }

  .header-admin {
    z-index: 10000000;
  }

  .tel-body a,
  .tel-body p,
  .text_contact a {
    font-size: 15px;
  }


  .text_contact a:hover  {
     color: red !important;
  }

  img.icon_contact {
    width: 3rem !important;
  }
  .contact_row {
    margin: 0;
  }

  .container_contact,
  .text_contact {
    padding: 10px 0;
  }

  .doctor-curso img {
    margin: 10px 0;
    width: 6rem;
  }

  .doctor-cursos-container {
    width: 80%;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .carousel .control-dots {
    bottom: -10px !important;
  }

  .horary_box {
    flex: 1 0 23%;
  }
}

@media (max-width: 320px) {
  .doctor-cursos-container {
    width: 100%;
  }
}
