.clinic-card {
  display: flex;
  gap: 32px;
  padding: 2rem;
  align-items: center;
  width: 100%;
}
.clinic-card:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
.clinic-card img {
  width: 67px;
  height: 67px;
  object-fit: contain;
  flex: 1;
}
.clinic-card div {
  flex: 2;
}

.clinic-card div p {
  color: black;
  font-size: 12px;
  margin-bottom: 4px;
}

.clinic-card h2 {
  color: #1c2afc;
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 0;
  flex: 2;
}

@media screen and (max-width: 770px) {
  .clinic-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .clinic-card div {
    flex: 1;
  }
  .clinic-card h2 {
    font-size: 1.5rem;
  }
}
