.hero-container {
  margin-bottom: 2rem;
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.img-copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 10rem;

  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  position: relative;
}

.line {
  width: 5rem;
  background: white;
  border: 2px solid white;
}

.busco {
  margin: 0.5rem 0;

  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}

.img-copy {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.orange-container {
  padding: 1rem;
  background: #fc8902;
  background: #fc8902;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.body h2 {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}

.body p {
  font-size: 20px;
}

.body img {
  width: 46px;
}

.body h3 {
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
}

.body p.small {
  font-size: 12px;
  font-weight: bold;
}

.dropdowns button {
  background: #0331fc;
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  text-transform: uppercase;
  width: 16rem;
}

.dropdowns .btn-primary {
  font-size: 1.5rem !important;
  padding: 1rem 2rem;
}

.dropdown-toggle::after {
  position: absolute;
  top: 2rem !important;
  margin: auto;
}

.actions {
  margin: 1rem 0;
}

.credenciales {
  display: flex;
  align-items: center;
  justify-content: center;
}

.credenciales p {
  margin-right: 1rem;
}

@media (min-width: 576px) {
  .busco {
    font-size: 46px;
    margin: 1rem 0;
  }
  .copy {
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .hero-container {
    display: flex;
  }

  .hero-image {
    flex: 1 0 50%;
  }

  .hero-image img {
    object-fit: contain;
    max-width: 100%;
  }

  .orange-container {
    flex: 1 0 50%;
  }

  .busco {
    font-size: 30px;
  }

  .copy {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .busco {
    font-size: 46px;
    margin: 2rem 0;
  }

  .copy {
    font-size: 28px;
  }

  .line {
    width: 10rem;
  }

  .hero-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
}
