.Footer {
  background: url("./Footer_0.webp");
  color: #ffffff;
  padding: 60px 0;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
}

.Footer .red-gine-container {
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  max-width: 41.66667%;
}

.links a {
  cursor: pointer;

  color: #ffffff;
}
.links a:hover {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff !important;
  font-weight: 600;
  color: white;
}
.links a:last-child {
  margin-bottom: 0;
}

.right {
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}
.right p {
  margin-bottom: 10px;
}

.right p:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .Footer .red-gine-container {
    display: flex;
    flex-direction: row;

    width: 100% !important;
    max-width: 1900px !important;

    padding: 0 100px;
  }
  .links {
    flex: 1 0;
    margin-bottom: 0px;
  }
  .right {
    flex: 1 0;
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
}
