.red-gine-container {
  width: 95%;
  margin: 0 auto;
  padding: 6px 0;

  max-width: 1140px !important;
}

.fade-in-slow {
  animation: fadein 0.8s forwards ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-fast {
  animation: fadein 0.4s forwards ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-item {
  font-size: 1rem !important;
  box-sizing: border-box !important;
  border-bottom: 1px dotted #fc8902 !important;
}

.dropdown-item:last-child {
  border-bottom: 0 !important;
}

.dropdown-menu.show {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 15vh !important;
  width: 100% !important;
}

.dropdown-toggle {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.orange-button,
.back-button,
.del-button {
  border: none;
  color: white;
  text-align: center;
  align-self: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  font-style: italic;
  border: 0;
  display: inline-block;
  margin: 3px;
  padding: 1rem;
  color: white;
  cursor: pointer;
}

.orange-button {
  background: #fc8902;
}

.back-button {
  background-color: #1c2afc;
  font-style: normal;
}

.del-button {
  background-color: red;
  font-style: normal;
}

.del-button:hover {
  text-decoration: underline;
}
.del-button:active {
  background: rgb(226, 69, 69);
}

a.DropdownCustom.dropdown-item {
  white-space: pre-line;
}

@media screen and (max-width: 1000px) {
  .red-gine-container {
    width: 90%;
  }
}
@media screen and (max-width: 770px) {
}

@media screen and (max-width: 420px) {
  .dropdown-menu.show {
    max-height: 30vh !important;
  }
}
@media screen and (max-width: 320px) {
}

ul,
li {
  list-style: none;
}
