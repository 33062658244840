.clinicas-container {
  background: white;

  overflow: hidden;
  flex: 1;
}

.clinicas-container .red-gine-container {
  padding: 0 !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cli-container {
  margin-top: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropDown-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listOfClinics-container {
  display: flex;
  height: 660px;
  overflow-y: hidden;
  border: 2px solid #ff8a01;
  flex-wrap: wrap;
}
.left-container {
  width: 45%;
}
.right-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 55%;
  align-items: center;
  overflow: scroll;
}
.fade-in-fast {
  margin-top: 3rem;
}

.dropDown-menu-item {
  flex: 1;
  width: 15%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.dropDown-menu-item:last-child {
  margin-right: 0rem !important;
}

.dropDown-menu-item:last-child {
  margin-right: 0;
}

.dropdown button {
  border-radius: 0 !important;
  padding: 1rem;
}

.start-clinics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
}

.start-clinics h1 {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
}

.start-clinics h2 {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #0331fc;
}

.start-clinics button {
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 2rem;
  line-height: 39px;
  text-align: center;

  padding: 1rem;

  border: 0;
  background: #0331fc;
  color: white;
  margin-top: 3rem;
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 770px) {
  .listOfClinics-container {
    height: auto;
  }
  .dropDown-menu {
    justify-content: space-between;
  }

  .has_muni {
    justify-content: flex-start;
  }

  .has_muni .dropDown-menu-item {
    flex: 1 0 30%;
    max-width: 30%;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .dropDown-menu-item {
    flex: 1 0 33%;
    max-width: 33%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .left-container {
    width: 100%;
  }
  .right-container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .dropDown-menu-item {
    flex: 1 0 45% !important;
    max-width: 49% !important;
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }

  .dropDown-menu-item:nth-child(odd) {
    margin-right: 0.5rem !important;
  }

  .dropDown-menu-item:last-child {
    margin-right: 0rem !important;
    margin-bottom: 0rem !important;
  }
}
@media screen and (max-width: 320px) {
}
