/* canvas {
  width: 540px;
  height: 660px;
} */
.map-container {
  width: 540px !important;
  height: 660px !important;
  border-radius: 5px;
}
.mapboxgl-map {
  width: 100% !important;
}

/* Marker */

.marker {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.marker-clinic {
  background-image: url('../../assets/clinica.png');
}
.marker-Mujer {
  background-image: url('../../assets/doctora.png');
}
.marker-Hombre {
  background-image: url('../../assets/doctor.png');
}
/* Circle */
.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: blue;
  cursor: pointer;
}

.circle:before,
.circle:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid blue;
  border-radius: 50%;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

@media screen and (max-width: 770px) {
  .map-container {
    width: 100% !important;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
