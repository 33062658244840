.clinic-prev {
  margin: 5px auto;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid gray;
}

.clinic-icon img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

.clinic-prev div {
  display: flex;
  justify-content: center;
}

.clinic-prev div p {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

.clinic-prev div h2 {
  text-align: left;
  color: #1c2afc;
  font-weight: bold;
  font-size: 2.5rem;
}

.prev-control {
  margin: 5px 0;
}
.opacity {
  opacity: 0.5;
}

.loader-div {
  height: 15rem;
}

.prev-doctor {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 770px) {
  .clinic-prev {
    flex-direction: column;
    justify-content: center;
  }
  .clinic-prev div h2 {
    text-align: center;
    font-size: 2rem;
  }
  .clinic-prev div p {
    font-size: 1rem;
  }

  .clinic-prev div p,
  .clinic-prev div h2,
  .clinic-prev div h3 {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
}
